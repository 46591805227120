
import { Component, Vue } from 'nuxt-property-decorator'
import GibddForm from './GibddForm.vue'

@Component({
  components: {
    GibddForm
  }
})
export default class extends Vue {
}
