
import { Component, Vue } from 'nuxt-property-decorator'
import AbstractBlock, { Paragraph } from '../AbstractBlock.vue'

@Component({
  name: 'GuaranteeInfo',
  components: { AbstractBlock }
})
export default class extends Vue {
  content: Paragraph[] = [
    {
      type: 'text',
      value: 'Наш сервис гарантирует проведение платежей. Если задолженность не пропадает сразу после оплаты, не выполняйте операцию повторно, дождитесь обновления базы. При необходимости в это время вы можете предоставить квитанцию проверяющим органам. В случае сбоев в банковских системах или в базе ГИБДД, из-за которых задолженность остается даже после оплаты, мы сами разрешаем спорную ситуацию ― штраф аннулируется или к вам возвращаются деньги. Но обычно задолженность исчезает из базы в течение нескольких минут.'
    },
    {
      type: 'text',
      value: 'При оплате все данные шифруются по протоколу TLS. Системы сертифицированы по стандарту PCI DSS, они соответствуют международным требованиям безопасности к проведению платежных операций.'
    }
  ]
}
