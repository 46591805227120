var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input-group",class:{ 'has-error': _vm.isError }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.plateNum),expression:"plateNum"},{name:"mask",rawName:"v-mask",value:(_vm.plateNumMask),expression:"plateNumMask"},{name:"tooltip",rawName:"v-tooltip.top",value:({
      html:true,
      distance:8,
      theme:'info-text',
      content:`Допустимые символы для поля:<br>
      <ul>
        <li>цифры - <span>0-9</span>;</li>
        <li>буквы (кириллица) - <span>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>
      </ul>
        `,
      triggers: [],
      shown: _vm.isTooltipShown || _vm.isError
    }),expression:"{\n      html:true,\n      distance:8,\n      theme:'info-text',\n      content:`Допустимые символы для поля:<br>\n      <ul>\n        <li>цифры - <span>0-9</span>;</li>\n        <li>буквы (кириллица) - <span>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>\n      </ul>\n        `,\n      triggers: [],\n      shown: isTooltipShown || isError\n    }",modifiers:{"top":true}}],staticClass:"col-8",attrs:{"id":_vm.numId,"type":"text","placeholder":"А000АА","required":_vm.required},domProps:{"value":(_vm.plateNum)},on:{"input":[function($event){if($event.target.composing)return;_vm.plateNum=$event.target.value},function($event){_vm.$emit('input', $event.target.value.replace(/\s+/g, '') + _vm.plateReg)}],"keypress":(e) => {!e.key.match(_vm.plateNumPatternWithNums) ? _vm.isTooltipShown = true : _vm.isTooltipShown = false},"blur":function($event){_vm.isTooltipShown = false}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.plateReg),expression:"plateReg"},{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],ref:'regInput_' + _vm.id,staticClass:"col-4",attrs:{"id":_vm.regId,"type":"text","maxlength":"3","placeholder":"777","required":_vm.required},domProps:{"value":(_vm.plateReg)},on:{"input":[function($event){if($event.target.composing)return;_vm.plateReg=$event.target.value},function($event){_vm.$emit('input', _vm.plateNum.replace(/\s+/g, '') + $event.target.value)}]}})])
}
var staticRenderFns = []

export { render, staticRenderFns }