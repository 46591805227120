
import { Component, Vue } from 'nuxt-property-decorator'
import AbstractBlock, { Paragraph } from '../AbstractBlock.vue'

@Component({
  name: 'PaymentFinesInfo',
  components: { AbstractBlock }
})
export default class extends Vue {
  content: Paragraph[] = [
    {
      type: 'text',
      value: 'В нашем сервисе можно сразу погасить все штрафы. Внизу страницы с результатами поиска вы увидите соответствующую форму. В ней нужно указать ФИО водителя и электронную почту, куда позже придет квитанция об оплате.'
    },
    {
      type: 'text',
      value: 'Мы принимаем любые банковские карты и мгновенно передаем информацию об оплате в ГИБДД и ГИС ГМП с помощью шлюзов Федерального Казначейства. В большинстве случаев задолженность аннулируется за несколько минут, квитанция приходит к вам и сохраняется в сервисе.'
    },
    {
      type: 'text',
      value: 'Все платежи обрабатывает сервис PayU. Это международная система безопасного подключения различных способов оплаты в интернете ― банковских карт и электронных денег. В ней объединены популярные платежные инструменты от Visa и MasterCard до QIWI и Деньги@Mail.ru.'
    }
  ]
}
