
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export interface Paragraph {
  type: 'text' | 'list' | 'subtitle';
  value: string | string[];
}

@Component({})
export default class extends Vue {
  @Prop() title?: string
  @Prop() icon?: string
  @Prop() content?: Paragraph[]
}
