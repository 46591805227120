
import { Component, Vue } from 'nuxt-property-decorator'
import AbstractBlock, { Paragraph } from '../AbstractBlock.vue'

@Component({
  name: 'MobileAppInfo',
  components: { AbstractBlock }
})
export default class extends Vue {
  content: Paragraph[] = [
    {
      type: 'text',
      value: 'Проверка штрафов ГИБДД в мобильном приложении выполняется разными способами, как и на сайте. После установки приложения и заполнения данных вам автоматически начинают поступать уведомления о появлении новых задолженностей. Поиск также осуществляется несколькими способами: по СТС и номеру машины, УИН или номеру ВУ. Приложение дает возможность погасить задолженность сразу же после оповещения в любое время и в любом месте. '
    }
  ]
}
